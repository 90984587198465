import { Application } from '@hotwired/stimulus'

// import CartController from './controllers/cart_controller.js'
// import ProductController from './controllers/product_controller.js'
// import LightboxController from './controllers/lightbox_controller.js'
// import ExpandController from './controllers/expand_controller.js'

const application = Application.start()
// application.register('cart', CartController)
// application.register('product', ProductController)
// application.register('lightbox', LightboxController)
// application.register('expand', ExpandController)

// console.log('INDEX.JS LOADED')
